@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PrimaryFont;
  src: url("../assets/fonts/Inter-VariableFont_slnt\,wght.ttf");
}

@font-face {
  font-family: Covered_By_Grace;
  src: url("../assets//fonts/CoveredByYourGrace-Regular.ttf");
}

@font-face {
  font-family: Nunito_sans;
  src: url("../assets//fonts/NunitoSans-VariableFont_YTLC\,opsz\,wdth\,wght.ttf");
}

:root {
  --title-txt-color: #404040;
  --common-txt-color: #58595d;
  --theme-txt-color: #e62170;
  --theme-border-color: #e62170;
  --nav-text-color: #2e2f35;
  --background1: #fcfcfc;
  --backgorund2: #fdf3ea;
  --background3: #ffc759;
  /* --background4: linear-gradient(301deg, rgba(255, 1, 47, 0.99) 7.04%, rgba(255, 1, 62, 0.00) 96.53%); */
  --background4: linear-gradient(301deg, #ffeaea 7.04%, #fffbfb 96.53%);
  --background5: #fff9e8;
  --background6: #F6F6F6;
}

.background1 {
  background: var(--background1);
}

.background2 {
  background: var(--backgorund2);
}

.background4 {
  background: var(--background4);
}

.background3 {
  background: var(--background3);
}

.background5 {
  background: var(--background5);
}

.background6 {
  background: var(--background6);
}

body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: PrimaryFont;
  font-style: normal;
  font-size: 16px;
  color: var(--common-color);
  scroll-behavior: smooth;
}

img {
  cursor: pointer;
  pointer-events: none;
  user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.navbar-toggler {
  border: 0;
  width: 2rem;
  height: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  position: relative;
  font-size: 1rem;
  line-height: 1;
  border-radius: 4px;
  transition: box-shadow 0.15s ease-in-out;
}

.navbar-toggler-icon {
  display: inline-block;
  vertical-align: middle;
  height: 2px;
  width: 1.55em;
  background: currentColor;
  border-radius: 10px;
  transition: top 0.2s 0.2s, bottom 0.2s 0.2s, transform 0.2s, opacity 0s 0.2s;
  position: relative;
}

.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  content: "";
  display: block;
  height: inherit;
  width: inherit;
  border-radius: inherit;
  background: inherit;
  position: absolute;
  left: 0;
  transition: inherit;
}

.navbar-toggler-icon:before {
  top: -0.47em;
}

.navbar-toggler-icon:after {
  bottom: -0.47em;
}

.navbar-toggle-show .navbar-toggler-icon {
  transform: rotate(45deg);
  transition: top 0.3s, bottom 0.3s, transform 0.3s 0.3s, opacity 0s 0.3s;
}

.navbar-toggle-show .navbar-toggler-icon::before {
  top: 0;
  transform: rotate(-90deg);
}

.navbar-toggle-show .navbar-toggler-icon::after {
  bottom: 0;
  opacity: 0;
}

.nav_each_list {
  color: var(--nav-text-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  padding: 10px 0px;
  position: relative;
  cursor: pointer;
}

.default-text-color {
  color: var(--common-txt-color);
}

.image-view {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-text-color {
  color: var(--nav-text-color);
}

.title-text-color {
  color: var(--title-txt-color);
}

.theme-text-color {
  color: var(--theme-txt-color);
}

.nav_list_wrapper .active::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  border-bottom: 2px solid var(--theme-txt-color);
}

.nav_list_wrapper .active {
  color: var(--theme-txt-color);
}

.food_category {
  font-family: Covered_By_Grace;
}

.cover_font {
  font-family: Covered_By_Grace;
}

.nunita_font {
  font-family: Nunito_sans;
}

.food_change {
  position: relative;
  top: -10px;
  transition: 0.5s all;
}

.food_slide .food_transform {
  top: 0px;
}

.flex_jc_ac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-phrase {
  max-width: 459.96px;
  /* height: 194px; */
  box-shadow: -40px 100px 70px 0px rgba(122, 79, 79, 0.07),
    40px 0px 70px 0px rgba(123, 67, 67, 0.07);
}

.box {
  opacity: 0;
  position: absolute;
  /* width: 100%; */
}

.b1 {
  animation: ani1 15s infinite;
}

.b2 {
  animation: ani2 15s infinite;
}

.b3 {
  animation: ani3 15s infinite;
}

.b4 {
  animation: ani4 15s infinite;
}

.b5 {
  animation: ani5 15s infinite;
}
.b6 {
  animation: ani7 15s infinite;
}
/* @keyframes ani1 {
    0% {
      opacity: 0;
    }
  
    50% {
      opacity: 1;
    }
  
    100% {
      opacity: 0;
    }
  } */

@keyframes ani1 {
  0% {
    opacity: 0;
  }

  5% {
    opacity: 1;
  }

  10% {
    opacity: 0;
  }
}

@keyframes ani2 {
  15% {
    opacity: 0;
  }

  20% {
    margin-top: 0px;
    opacity: 1;
  }

  25% {
    margin-top: 0px;
    opacity: 0;
  }
}

@keyframes ani3 {
  30% {
    opacity: 0;
  }

  35% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }
}

@keyframes ani4 {
  45% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 0;
  }
}

@keyframes ani5 {
  60% {
    opacity: 0;
  }

  65% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }
}
@keyframes ani7 {
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bg-set {
  background-image: url(../assets/images/Group.svg);
  background-repeat: no-repeat;
}

.bg-curve-set {
  background-image: url(../assets/images/Ellipse.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  /* position: absolute;
    bottom: 0px;
    right: 0;
    width: 289px;
    height: 213px */
}

.box-wrapper {
  /* transition: 1s all; */
}

.point {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: 1s all;
}

.slide_content,
.slide_content p,
.slide_content div p {
  color: #404040;
  font-size: 20px;
  line-height: 32px;
}

.point .p1 {
  height: 0px;
  opacity: 0;
  transform: translate(0px, -50px);
}

@keyframes aniy {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.point .cont_trans {
  transform: translate(0px, 0px);
  height: auto;
  opacity: 1;
  transition: 2s all;
}

.point .title_trans {
  /* opacity: 0; */
  animation: ani6 1s all;
}

.box-wrapper {
  /* transition: 1s all; */
}

.point {
  /* transition: 1s all; */
}

@keyframes ani6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.slide_title {
  color: #202020;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.5px;
  font-weight: 700;
}

.slide_num_title {
  color: #e62170;
  font-family: Covered_By_Grace;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 4px;
}

.download-btn {
  box-shadow: 0px 30px 50px 0px rgba(75, 75, 75, 0.24);
}

.reviews_wrapper {
  border: 1px solid #f3f3f3;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(33, 33, 33, 0.03);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.review_title {
  color: #2e2f35;
  text-align: center;
  /* font-family: Inter; */
  font-size: 63px;
  font-style: normal;
  font-weight: 700;
  line-height: 63px;
  letter-spacing: -2px;
}

.review_desc {
  color: #58595d;
  text-align: center;
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.elipse1-bg {
  /* width: 100%;/ */
  /* height: 400px; */
  position: absolute;
  top: 200px;
  right: 0;
  width: 169px;
  height: 307px;
  background-image: url("../assets/images/elipse1.svg");
  background-repeat: no-repeat;
}

.elipse2-bg {
  /* width: 100%;/ */
  /* height: 400px; */
  position: absolute;
  top: 240px;
  right: 0;
  width: 169px;
  height: 307px;
  background-image: url("../assets/images/elipse2.svg");
  background-repeat: no-repeat;
}

.teams-wrapper {
  border-radius: 50px 0px;
  border: 4px solid #f3a101;
  background: #f8b737;
  box-shadow: 0px 4px 12px 0px #feda96;
}
.change-navbar-style {
  background-color: #fff;
  box-shadow: 0 2px 10px 0 hsla(240, 2%, 88%, 0.5);
  transition: "background-color 0.3s ease";
}
.header {
  position: fixed;
  width: 100%;
  z-index: 99;
}
.fs-main p {
  text-align: justify;
  margin-top: 20px;
}
.teams-bg {
  background-image: url("../assets/images/teamleader.svg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
}
.team-card {
  background-color: #fff;
  border-radius: 12px;
  cursor: pointer;
  filter: drop-shadow(-2px 4px 24px rgba(0, 0, 0, 0.08));
}

.linked {
  display: none;
}
